<template>
    <div class="hospital-content">
        <div class="hospital-live" v-if="Live_list.length > 0">
            <div class="hospital-live-main">
                <hospital-title title="直播课程" :href="`/seminars?keyword=${$route.params.keyword}`"/>
                <div class="home-live-main" v-if="Live_list.length>0">
                    <div class="home-live-one" @click="toPath((Live_list[0].live_state == 1 || Live_list[0].live_state == 2)?`/seminars/${Live_list[0].id}/detail`:`/seminars/${Live_list[0].vid || Live_list[0].id}/details`)">
                        <div class="home-live-one-img">
                            <img :src="Live_list[0].image">
                        </div>
                        <div class="home-live-one-other">
                            <div class="home-live-one-title">{{ Live_list[0].title }}</div>
                            <div class="home-live-one-time">{{ Live_list[0].start_time }}</div>
                            <div class="home-live-one-desc">
                                {{ Live_list[0].price == '0.00'?'免费': Live_list[0].price }}
                            </div>
                        </div>

                    </div>

                    <div class="home-live-other">
                        <live-course-item v-for="(item,index) in Live_list" :key="item.id" v-if="index>0 && index<7" :item="item" @click.native="toPath((item.live_state == 1 || item.live_state == 2)?`/seminars/${item.id}/detail`:`/seminars/${item.vid || item.id}/details`)"></live-course-item>
                    </div>
                </div>
            </div>
        </div>


        <div class="hospital-course" v-if="course_list.length > 0">
            <hospital-title title="热门课程" :href="`/lct?keyword=${$route.params.keyword}`"/>
            <div class="home-hotCourse-main">
                <hot-course-item v-for="(item,index) in course_list" :key="item.cid" :item="item" @click.native="toPath(`/lct/${item.cid}/detail`)"/>
            </div>
        </div>

        <div class="hospital-course" v-if="courseListPrice.length > 0">
            <hospital-title title="会员课程" :href="`/courseVip?keyword=${$route.params.keyword}`"/>
            <div class="home-hotCourse-main">
                <vip-course-item v-for="(item,index) in courseListPrice" :key="item.cid" :item="item" @click.native="toPath(`/courseVip/${item.cid}/detail`)"/>
            </div>
        </div>

        <div class="hospital-home-news" v-if="information_list.length > 0">
            <div class="hospital-home-news-main">
                <hospital-title title="最新资讯" :href="`/actciles?keyword=${$route.params.keyword}`"/>
                <div class="hospital-home-news-contact">
                    <div class="hospital-home-news-swiper">
                        <swiper :options="swiperOption">
                            <swiper-slide v-for="(item,index) in information_list" :key="item.id" @click.native="toPath(`/actciles/${item.id}/detail`)" v-if="index<5">
                                <div class="hospital-home-news-img">
                                    <el-image
                                            style="width: 100%; height: 100%"
                                            :src="item.img"
                                            fit="cover"></el-image>
                                </div>
                                <div class="hospital-home-news-title">{{ item.title }}</div>
                            </swiper-slide>
                            <div class="swiper-pagination" slot="pagination"></div>
                        </swiper>
                    </div>
                    <div class="hospital-home-news-list">
                        <div class="hospital-home-news-item hide" v-for="(item,index) in information_list" :key="index" v-if="(information_list.length<5 && index<=10) || (information_list.length>5 && index>4 && index<15)" @click="toPath(`/actciles/${item.id}/detail`)">{{ item.title }}</div>
                    </div>
                </div>
            </div>
        </div>
        <no-data v-if="Live_list.length<=0 && course_list.length<=0 && information_list.length<=0 && courseListPrice.length<=0"/>
        <!--<div class="hospital-home-coupe">
            <hospital-title title="科普知识" href="/actciles"/>
            <div class="hospital-home-coupe-list">
                <div class="hospital-home-coupe-item" v-for="(item,index) in kepu_list" :key="index">
                    <div class="hospital-home-coupe-item-img">
                        <img :src="item.img"/>
                    </div>
                    <div class="hospital-home-coupe-item-title">
                        {{ item.title }}
                    </div>
                </div>
            </div>
        </div>-->
    </div>
</template>

<script>
    export default {
        data() {
            return {
                Live_list: {},
                course_list: {},
                information_list: {},
                kepu_list: {},
                courseListPrice: {},

                swiperOption: {
                    pagination: {
                        el: '.swiper-pagination',
                        clickable: true
                    },
                    autoplay: {
                        delay: 5000,
                        disableOnInteraction: true // 手动切换之后继续自动轮播
                    },
                    loop: true
                },
            }
        },
        mounted() {
            this.getHomeData();
        },
        watch: {
            $route: {
                handler() {
                    // console.log(this.$route.params.keyword);
                    this.getHomeData();
                    //深度监听，同时也可监听到param参数变化
                },
                deep: true,
            }
        },
        methods: {
            getHomeData() {
                this.$axios.post(this.apiUrl.searchIndex, {
                    keyword: this.$route.params.keyword
                }).then(res => {
                    this.Live_list = res.data.Live_list;// 直播信息
                    this.course_list = res.data.course_list;// 热门课程信息
                    this.information_list = res.data.information_list;// 最新资讯信息
                    this.courseListPrice = res.data.courseListPrice;// 付费课程
                    // this.kepu_list = res.data.kepu_list;// 最新科普信息
                })
            },
            toPath(path) {
                this.$router.push({path: path})
            }
        }
    }
</script>

<style scoped>

</style>
